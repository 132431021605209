import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

export const ANNOUNCEMENT_NOTICE_DURATION = ONE_MONTH_IN_SECONDS;
export const ANNOUNCEMENT_LOADING_TIME = 1000;
export const ANNOUNCEMENT_MIN_HEIGHT = 40;
export const RECALCULATE_STYLES_TIME_IN_MS = 10;
export const ANNOUNCEMENT_MOBILE_HEIGHT = '60px';
export const ANNOUNCEMENT_LOCATIONS = {
    CMS_PAGE: 'cms',
    PRODUCT: 'product',
    SEARCH: 'search',
    CART: 'cart',
    CONTACT: 'contact',
    CUSTOM: 'custom',
    CATEGORY: 'category',
};
