import { STRING_ONLY_ATTRIBUTE_CODES } from '@scandipwa/scandipwa/src/component/ProductAttributeValue/ProductAttributeValue.config';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import { INSPIRATION_KEY } from 'Route/CategoryPage/CategoryPage.config';
import { ProductAttributeValue as SourceProductAttributeValue } from 'SourceComponent/ProductAttributeValue/ProductAttributeValue.component';
import { getBooleanLabel } from 'Util/Product';

import './ProductAttributeValue.override.style';

/** @namespace Pwa/Component/ProductAttributeValue/Component/ProductAttributeValue */
export class ProductAttributeValue extends SourceProductAttributeValue {
    getOptionLabel(value) {
        const {
            attribute: { attribute_options, is_boolean },
            isProductCountVisible,
        } = this.props;

        if (attribute_options) {
            const optionValues = attribute_options[value];

            if (optionValues) {
                if (!isProductCountVisible) {
                    const { label } = optionValues;

                    return { ...optionValues, labelText: label };
                }

                const { label, count = 0 } = optionValues;

                const adjustedLabel = getBooleanLabel(label, is_boolean);

                return {
                    ...optionValues,
                    label: `${adjustedLabel} (${count})`,
                    labelText: adjustedLabel,
                    count,
                };
            }
        }

        if (typeof value === 'string') {
            const optionValues = attribute_options[value];

            if (!optionValues?.count) {
                return {};
            }

            return { label: value, labelText: value, count: optionValues?.count };
        }

        return {};
    }

    renderColorValueData(label, style) {
        const { isFormattedAsText, isSelected } = this.props;
        const colorLabel = label;

        if (isFormattedAsText) {
            return label || __('N/A');
        }

        return (
            <>
                <data block="ProductAttributeValue" elem="Color" value={label} title={label} style={style} />
                <span block="ProductAttributeValue" elem="ColorText" mods={{ isSelected }} title={label}>
                    {colorLabel}
                </span>
            </>
        );
    }

    renderColorValue(color, label) {
        const { isFormattedAsText, isSelected } = this.props;
        const isLight = this.getIsColorLight(color);
        const colorLabel = label;

        if (isFormattedAsText) {
            return label || __('N/A');
        }

        const style = {
            '--option-background-color': color,
            '--option-border-color': isLight ? '#dddddd' : color,
            '--option-check-mark-background': isLight ? '#000' : '#fff',
        };

        return (
            <Field
                type={FIELD_TYPE.checkbox}
                attr={{
                    id: colorLabel,
                    name: colorLabel,
                    defaultChecked: isSelected,
                    checked: isSelected,
                }}
                label={this.renderColorValueData(label, style)}
                mix={{
                    block: 'ProductAttributeValue',
                    elem: 'Text',
                    mods: { isSelected },
                }}
            />
        );
    }

    renderImageValue(img, label) {
        const {
            isFormattedAsText,
            isSelected,
            attribute: { attribute_code, attribute_label },
        } = this.props;

        if (isFormattedAsText) {
            return label || __('N/A');
        }

        const style = {
            // stylelint-disable-next-line value-keyword-case
            '--option-is-selected': isSelected ? 1 : 0,
        };

        const labelValue = attribute_code === INSPIRATION_KEY ? attribute_label : label;

        return (
            <>
                <img
                    block="ProductAttributeValue"
                    elem="Image"
                    mods={{ isSelected }}
                    src={`/media/attribute/swatch${img}`}
                    alt="icon"
                    width="32"
                    height="32"
                />
                <data
                    block="ProductAttributeValue"
                    elem="Image-Overlay"
                    value={labelValue}
                    title={labelValue}
                    style={style}
                />
                {this.getCheckboxLabel(labelValue)}
            </>
        );
    }

    renderSelectAttribute() {
        const {
            attribute: { attribute_value, attribute_code, has_swatch },
        } = this.props;
        const attributeOption = this.getOptionLabel(attribute_value);
        const { label, labelText, count, swatch_data } = attributeOption;

        // skip attributes without valid swatches
        if ((!swatch_data && has_swatch) || !label) {
            return null;
        }

        if (!swatch_data || STRING_ONLY_ATTRIBUTE_CODES.includes(attribute_code)) {
            return this.renderStringValue(labelText, null, count);
        }

        const { value, type } = swatch_data;

        switch (type) {
            case '0':
                return this.renderStringValue(value, labelText, count);
            case '1':
                return this.renderColorValue(value, label);
            case '2':
                return this.renderImageValue(value, label);
            default:
                return this.renderStringValue(labelText || __('N/A'), labelText, count);
        }
    }

    render() {
        const {
            getLink,
            attribute,
            isAvailable,
            attribute: { attribute_code, attribute_value, attribute_label },
            mix,
            isFormattedAsText,
            showProductAttributeAsLink,
            isSelected,
        } = this.props;

        if ((attribute_code && !attribute_value) || (attribute_code && !attribute_label)) {
            return null;
        }

        const href = getLink(attribute);
        // Invert to apply css rule without using not()
        const isNotAvailable = !isAvailable;

        if (isFormattedAsText) {
            return (
                <div block="ProductAttributeValue" mix={mix} mods={{ isSelected }}>
                    {this.renderAttributeByType()}
                </div>
            );
        }

        if (!showProductAttributeAsLink) {
            return (
                <span
                    block="ProductAttributeValue"
                    mods={{ isNotAvailable, isSelected }}
                    onClick={this.clickHandler}
                    onKeyDown={this.clickHandler}
                    role="link"
                    tabIndex="-1"
                    aria-hidden={isNotAvailable}
                    mix={mix}
                >
                    {this.renderAttributeByType()}
                </span>
            );
        }

        return (
            <a
                href={href}
                block="ProductAttributeValue"
                mods={{ isNotAvailable, isSelected }}
                onClick={this.clickHandler}
                aria-hidden={isNotAvailable}
                mix={mix}
            >
                {this.renderAttributeByType()}
            </a>
        );
    }
}

export default ProductAttributeValue;
