import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CloseIcon from 'Component/CloseIcon';
import Icons from 'Component/Icons';

import { ANNOUNCEMENT_LOADING_TIME, ANNOUNCEMENT_MIN_HEIGHT, ANNOUNCEMENT_MOBILE_HEIGHT } from './Announcement.config';

import './Announcement.style';

/** @namespace Domneo/Announcement/Component/Announcement/Component/AnnouncementComponent */
export class AnnouncementComponent extends PureComponent {
    static propTypes = {
        announcement: PropTypes.shape({
            background_color: PropTypes.string,
            can_close: PropTypes.bool,
            content: PropTypes.string,
            display_from: PropTypes.string,
            display_to: PropTypes.string,
            places: PropTypes.string,
            text_color: PropTypes.string,
        }),
        hideAnnouncementNotice: PropTypes.func,
        expandContent: PropTypes.func,
        isContentExpanded: PropTypes.bool,
        isMobile: PropTypes.bool,
    };

    static defaultProps = {
        announcement: {
            background_color: '',
            can_close: true,
            content: '',
            display_from: '',
            display_to: '',
            places: '',
            text_color: '',
        },
        hideAnnouncementNotice: () => {},
        expandContent: () => {},
        isContentExpanded: false,
        isMobile: false,
    };

    componentDidMount() {
        this.setAnnouncementHeightToOffsetHeight();
    }

    recalculateAnnouncementHeight() {
        const { isMobile } = this.props;
        const announcementNotice = document.getElementById('AnnouncementNotice');
        const announcementHeight = isMobile ? ANNOUNCEMENT_MOBILE_HEIGHT : `${announcementNotice?.offsetHeight}px`;

        setTimeout(() => {
            document.documentElement.style.setProperty('--demo-notice-height', announcementHeight);
        }, ANNOUNCEMENT_LOADING_TIME);
    }

    setAnnouncementHeightToOffsetHeight() {
        const { isMobile } = this.props;
        const announcementNotice = document.getElementById('AnnouncementNotice');
        const announcementHeight = isMobile ? ANNOUNCEMENT_MOBILE_HEIGHT : `${announcementNotice?.offsetHeight}px`;
        announcementNotice?.style.setProperty('--demo-notice-height', announcementHeight);

        if (announcementNotice?.offsetHeight > 0) {
            document.documentElement.style.setProperty('--demo-notice-height', announcementHeight);
            return;
        }
        document.documentElement.style.setProperty('--demo-notice-height', `${ANNOUNCEMENT_MIN_HEIGHT}px`);
        this.recalculateAnnouncementHeight();
    }

    renderContent() {
        const { announcement } = this.props;
        const { background_color, content } = announcement || {};

        if (!content) {
            return null;
        }

        return (
            <div id="AnnouncementNotice" block="Announcement" style={{ backgroundColor: background_color }}>
                {this.renderHtmlContent()}
                {this.renderHideNotice()}
                {this.renderExpandButton()}
            </div>
        );
    }

    renderHtmlContent() {
        const { announcement, isContentExpanded } = this.props;
        const { content, text_color } = announcement || {};

        return (
            <div
                block="Announcement"
                elem="Content"
                mods={{ expanded: isContentExpanded }}
                dangerouslySetInnerHTML={{ __html: content }}
                style={{ color: text_color }}
            />
        );
    }

    renderHideNotice() {
        const { announcement, hideAnnouncementNotice } = this.props;
        const { can_close } = announcement || {};

        if (!can_close) {
            return null;
        }

        return (
            <div block="Announcement" elem="CloseIcon" onClick={hideAnnouncementNotice}>
                <CloseIcon />
            </div>
        );
    }

    renderExpandButton() {
        const { expandContent, announcement, isMobile, isContentExpanded } = this.props;
        const { background_color } = announcement || {};

        if (isContentExpanded || !isMobile) {
            return null;
        }

        return (
            <div
                block="Announcement"
                elem="ExpandButton"
                onClick={expandContent}
                style={{ backgroundColor: background_color }}
            >
                <Icons name="chevron_double" />
            </div>
        );
    }

    render() {
        return <>{this.renderContent()}</>;
    }
}

export default AnnouncementComponent;
