/** @namespace Pwa/Util/Document/updateDocumentLang */
export const updateDocumentLang = (lang) => {
    document.documentElement.setAttribute('lang', lang);
};

/** @namespace Pwa/Util/Document/nodeStringRepresenation */
export const nodeStringRepresenation = (elem) => `
    <${elem.tagName.toLowerCase()} ${Array.from(elem.attributes, (attr) => `${attr.name}="${attr.value}"`).join(' ')}>
        ${elem.innerHTML || elem.children}
    </${elem.tagName.toLowerCase()}>
`;
