/* eslint-disable react/jsx-no-useless-fragment */

import parser from 'html-react-parser';
import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import domToReact from 'html-react-parser/lib/dom-to-react';

import Accordion from 'Component/Accordion';
import BlogWidget from 'Component/BlogWidget';
import ContactForm from 'Component/ContactForm';
import Image from 'Component/Image';
import Link from 'Component/Link';
import ReviewsSection from 'Component/ReviewsSection';
import Tabs from 'Component/Tabs';
import WidgetFactory from 'Component/WidgetFactory';
import { Html as SourceHtml } from 'SourceComponent/Html/Html.component';
import { nodeStringRepresenation } from 'Util/Document';
import { hash } from 'Util/Request/Hash';

import { getBackgroundImage, getButtonClasses, getDisplayType } from './Html.config';

/** @namespace Pwa/Component/Html/Component/Html */
export class Html extends SourceHtml {
    rules = [
        ...this.rules,
        {
            query: { attribs: [{ 'data-content-type': 'html' }] },
            replace: this.replaceHtml,
        },
        {
            query: { attribs: ['data-reviews'] },
            replace: this.replaceReviews,
        },
        {
            query: { attribs: ['data-blog-widget'] },
            replace: this.replaceBlogWidget,
        },
        {
            query: { attribs: ['data-contact-form'] },
            replace: this.replaceContactForm,
        },
        {
            query: { attribs: ['data-accordion'] },
            replace: this.replaceAccordion,
        },
        {
            query: { attribs: ['data-tabs'] },
            replace: this.replaceTabs,
        },
        {
            query: { attribs: ['data-element'] },
            replace: this.replaceElement,
        },
    ];

    replaceWidget({ attribs, parent }) {
        const { minifyForCrawlers } = this.props;

        return (
            <WidgetFactory
                displayType={getDisplayType(parent)}
                minifyForCrawlers={minifyForCrawlers}
                {...this.attributesToProps(attribs)}
            />
        );
    }

    replaceLinks({ name: Name, attribs, children }) {
        const { href, ...attrs } = attribs;

        if (href) {
            const isAbsoluteUrl = (value) => new RegExp('^(?:[a-z]+:)?//', 'i').test(value);
            const isSpecialLink = (value) => new RegExp('^(sms|tel|mailto):', 'i').test(value);

            if (!isAbsoluteUrl(href) && !isSpecialLink(href)) {
                const { className = '', ...attr } = this.attributesToProps({ ...attrs, to: href });

                return (
                    <Link className={getButtonClasses(className)} {...attr}>
                        {domToReact(children, this.parserOptions)}
                    </Link>
                );
            }

            const { className = '', ...attr } = this.attributesToProps({ href, ...attrs });

            return (
                <Name className={getButtonClasses(className)} {...attr}>
                    {domToReact(children, this.parserOptions)}
                </Name>
            );
        }
    }

    replaceImages({ attribs }) {
        const attributes = attributesToProps(attribs);
        const { noImgAttributesTransform } = this.props;

        if (attribs.src) {
            return <Image {...attributes} isPlain noImgAttributesTransform={noImgAttributesTransform} />;
        }
    }

    replaceElement({ name: Name, attribs, children }) {
        const { device: { isMobile } = {} } = this.props;
        const {
            'data-element': element,
            'data-content-type': contentType,
            'data-background-images': backgroundImages,
        } = this.attributesToProps(attribs);

        if (contentType === 'tabs') {
            return (
                <Tabs isPageBuilder defaultActive={attribs['data-active-tab']}>
                    {domToReact(children, this.parserOptions)}
                </Tabs>
            );
        }

        if (backgroundImages) {
            const { 'data-background-images': backgroundImages, style = {}, ...attr } = this.attributesToProps(attribs);
            const backgroundImage = getBackgroundImage(backgroundImages, isMobile);

            return (
                <Name {...attr} data-background-images={backgroundImages} style={{ ...style, backgroundImage }}>
                    {domToReact(children, this.parserOptions)}
                </Name>
            );
        }

        if (element === 'button' || element === 'empty_link') {
            const { className = '', ...attr } = this.attributesToProps(attribs);

            return (
                <Name {...attr} className={getButtonClasses(className)}>
                    {domToReact(children, this.parserOptions)}
                </Name>
            );
        }
    }

    replaceHtml({ children }) {
        try {
            return <>{parser(domToReact(children, this.parserOptions), this.parserOptions)}</>;
        } catch {
            return domToReact(children, this.parserOptions);
        }
    }

    replaceBlogWidget({ attribs }) {
        const { 'data-title': title, 'data-posts-number': postsNumber } = this.attributesToProps(attribs);
        return <BlogWidget title={title} postsNumber={postsNumber} hiddenSwipe />;
    }

    replaceReviews() {
        return <ReviewsSection />;
    }

    replaceContactForm() {
        return <ContactForm />;
    }

    replaceAccordion({ attribs, children }) {
        const { 'data-accordion-title': title } = this.attributesToProps(attribs);

        return <Accordion title={title}>{domToReact(children, this.parserOptions)}</Accordion>;
    }

    replaceTabs({ attribs, children }) {
        const { 'data-faq-title': title } = this.attributesToProps(attribs);

        return <Tabs title={title}>{domToReact(children, this.parserOptions)}</Tabs>;
    }

    replaceScript(elem) {
        const { attribs, children } = elem;
        const elemHash = hash(nodeStringRepresenation(elem));
        const scriptContent = children[0] ? children[0].data : '';
        const { htmlBody = false } = this.props;

        if (this.createdOutsideElements[elemHash]) {
            return <></>;
        }

        const script = document.createElement('script');

        Object.entries(attribs).forEach(([attr, value]) => script.setAttribute(attr, value));

        if (scriptContent) {
            script.appendChild(document.createTextNode(scriptContent));
        }

        if (htmlBody) {
            document.body.appendChild(script);
        } else {
            // ensure script isn't appended before the document is parsed
            setTimeout(() => document.head.appendChild(script), 10);
        }

        this.createdOutsideElements[elemHash] = true;

        return (
            <script
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                    __html: scriptContent,
                }}
                {...attribs}
            />
        );
    }

    render() {
        const { content } = this.props;

        if (!content) {
            return null;
        }

        return parser(content, this.parserOptions);
    }
}

export default Html;
